<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('settings.admins')"
      :sub-title="$t('settings.admins_sub_title')"
      btn
      variant="main"
      :btn-title="$t('settings.add_admin')"
      btn-prepend-icon="add-circle-icon"
      classes="text-bold-15 text-white w-cus-sm-100"
      @btnAction="addAdmin"
    />

    <div>
      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderApplicants"
      />
    </div>
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
              v-if="getAllAdministrators"
              :per-page="filterData.per_page"
              :items="getAllAdministrators"
              :fields="fields"
              :status="status"
              :custimized-items="[
                { name: 'phone' },
                { name: 'country' },
                { name: 'name' },
                { name: 'action' },
              ]"
            >
              <template slot="name" slot-scope="{ data }">
                <div class="d-flex justify-center align-items-center">
                  <b-avatar variant="" size="50">
                    <img v-if="data.item.photo" :src="data.item.photo" />
                    <default-user-image-icon v-else />
                  </b-avatar>
                  <span class="text-font-main mx-3">{{ data.item.name }}</span>
                </div>
              </template>
              <template slot="phone" slot-scope="{ data }">
                <div class="d-flex" dir="ltr">
                  <span class="text-font-main mx-3">{{ data.item.phone }}</span>
                </div>
              </template>
              <template slot="country" slot-scope="{ data }">
                <div class="d-flex">
                  <span v-if="data.item.country" class="text-font-main mx-3">
                    {{ jsonParseCountry(data.item.country) }}</span
                  >
                </div>
              </template>
              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                  variant="background"
                  toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                  menu-class="rounded-14"
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1" />
                  </template>
                  <b-dropdown-item
                    link-class="py-0min-height: 52px;"
                    @click="administratorDetails(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2" />
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.status == 1"
                    link-class="py-0min-height: 52px;"
                    @click="stoppingAdmin(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <refusal-icon class="mx-2" />
                      {{ $t('common.stopping') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-else
                    link-class="py-0min-height: 52px;"
                    @click="activationAdmin(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <activation-icon class="mx-2" />
                      {{ $t('common.activation') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </w-tables>
            <wameed-no-data
              v-if="getAllAdministrators && getAllAdministrators.length < 1"
              icon="nodata-icon"
              :title="$t('offers_page.no_data')"
              :sub-title="$t('offers_page.no_data_text')"
            />
          </div>
        </b-col>
        <warning-modal
          :variant="'main'"
          :variant2="'success'"
          :icon-name="'confirmation-icon'"
          :visible="showActivaAdministratorModal"
          :title="$t('common.activation_user_title')"
          :sub-title="$t('common.activation_user_subtitle')"
          :btn-title="$t('common.activation')"
          @close="showActivaAdministratorModal = false"
          @submitAction="activationAdministrator()"
        />
        <warning-modal
          :variant="'danger'"
          :variant2="'danger'"
          :icon-name="'stopping-icon'"
          :visible="showStoppingAdministratorModal"
          :title="$t('common.stopping_user_title')"
          :sub-title="$t('common.stopping_user_subtitle')"
          :btn-title="$t('common.stopping')"
          @close="showStoppingAdministratorModal = false"
          @submitAction="stoppingAdministrator()"
        />
        <b-col
          v-if="getAllAdministrators && getAllAdministrators.length > 0"
          lg="12"
          md="12"
          class="px-0"
        >
          <wameed-pagination
            v-model="filterData.page"
            :page="filterData.page"
            :total-items="getTotalAdministrators.totalItems"
            :per_page="filterData.per_page"
            @changePage="changePage"
            @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
// import ordersCard from "@/components/pages/orders/OrdersCard.vue";
import WTables from '@/components/pages/applicants/WTables.vue';
import WarningModal from '@/components/WarningModal.vue';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextArea from '@/components/TextArea.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    // ordersCard,
    WTables,
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
  },
  data() {
    return {
      showActivaAdministratorModal: false,
      showStoppingAdministratorModal: false,
      adminId: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      content: [
        {
          title: 'status',
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          data: [
            { title: this.$i18n.t('common.inactive'), id: 0 },
            { title: this.$i18n.t('common.active'), id: 1 },
            { title: this.$i18n.t('common.not_enabled'), id: 2 },
          ],
        },
      ],
      fields: [
        {
          key: 'name',
          label: this.$i18n.t('users_data.name'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$i18n.t('users_data.email'),
          sortable: true,
        },
        {
          key: 'phone',
          label: this.$i18n.t('users_data.phone'),
          sortable: true,
        },
        {
          key: 'country',
          label: this.$i18n.t('users_data.country'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('users_data.status'),
          sortable: true,
        },
        { key: 'action', label: '', sortable: false },
      ],
      status: [
        {
          0: 'inactive',
          1: 'active',
          2: 'not_enabled',
        },
        {
          0: 'danger',
          1: 'success',
          2: 'icon',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllAdministrators: 'getAllAdministrators',
      getTotalAdministrators: 'getTotalAdministrators',
    }),
  },
  watch: {},
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadAdministrators: 'loadAdministrators',
      changeAdministratorsStatus: 'changeAdministratorsStatus',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadAdministrators(this.filterData);
    },
    packageDetails(id) {
      this.$router.push({
        name: 'orders-list-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    applyFilter() {
      this.content.forEach((item) => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    administratorDetails(id) {
      this.$router.push({
        name: 'administrator-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    stoppingAdmin(id) {
      this.showStoppingAdministratorModal = true;
      this.adminId = id;
    },
    activationAdmin(id) {
      this.showActivaAdministratorModal = true;
      this.adminId = id;
    },
    activationAdministrator() {
      this.changeAdministratorsStatus({
        id: this.adminId,
        status: 1,
      }).then(() => {
        this.showActivaAdministratorModal = false;
        this.loadData();
      });
    },
    stoppingAdministrator() {
      this.changeAdministratorsStatus({
        id: this.adminId,
        status: 0,
      }).then(() => {
        this.showStoppingAdministratorModal = false;
        this.loadData();
      });
    },
    addAdmin() {
      this.$router.push({
        name: 'add-administrators',
        params: { lang: this.$i18n.locale },
      });
    },
    jsonParseCountry(json) {
      return JSON.parse(json).name;
    },
  },
};
</script>
